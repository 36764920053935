.services__container {
    display: flex;
    flex-direction: row;
    grid-template-columns: repeat(3, 1fr);
    /* gap: 10rem; */
    justify-content: space-between;
}

.hex {
    
    backdrop-filter: blur(15px);
    background:var(--color-bg-varient);
    margin-top: 30px;
    width: 140px;
    height: 120px;
    position: relative;
    display: inline-block;
    text-align: center;
    align-items: center;
    justify-content: center;
    -webkit-clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
    clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
    /* transform: rotate(30deg); */
}


/* .hex:before {
    content: " ";
    left: 0;
    width: 0; height: 0;
    border-bottom: 30px solid;
    border-color: inherit;
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
    position: absolute;
    top: -30px;
} */

/* .hex:after {
    content: "";
    width: 0;
    height: 0;
    left: 0;
    position: absolute;
    bottom: -30px;
    border-top: 30px solid;
    border-color: inherit;
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
} */
.hex img{
    width: 65px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); 
    transition: all .2s ease-in-out; 
}

.hex img:hover{
    transform: translate(-50%,-50%)scale(1.2);

}

.services-containers {
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    max-width: 250px;
    min-width: 210px;
}

.services-containers p, ul{
    color: var(--color-primary);
    
     /* color: #fff; */
}

.text {
    margin-top: 20px;
}

.blur-s{
    
        width: 15rem;
        height: 13rem;
        left: 40%;
}

@media screen and (max-width:600px) {
    .hex {}

    .services__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4rem;

    }

    .blur-s{
    
        width: 15rem;
        height: 13rem;
        left: 20px;
}
}